import { render, staticRenderFns } from "./videoPlayer.vue?vue&type=template&id=7fa9f491&scoped=true"
import script from "./videoPlayer.vue?vue&type=script&lang=js"
export * from "./videoPlayer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa9f491",
  null
  
)

export default component.exports