<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
    <el-dialog title="编辑模块" :append-to-body="true" :lock-scroll="false" modal @close="closeFunc"
        :visible="imagesEidtDialogType">
        <div class="editMain">
            <div class="menuList">
                <div class="navBox" @click="addItemFunc">
                    + 添加{{ dragItemName }}
                </div>
                <div class="bttomBox">
                    <div v-for="(item, index) in imgList" :key="index" class="itemBox"
                        :class="{ 'itemBox2': editListMode.id === item.id }" @click="getItemFunc(item, index)">
                        <!-- <div>{{ item.url }}</div> -->
                        <img style="height: 100%;width: 100%;" :src="item?.url" alt="" v-if="dragItemName == '图片'">
                        <VideoPlayer  :refsName="'myVideo' + item.id" :src="item.url" v-if="dragItemName == '视频'"></VideoPlayer>
                        <div v-if="dragItemName == 'pdf'">{{ item.title }}</div>
                        <div class="topMenu" v-show="editListMode.id === item.id">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    <el-button>编辑</el-button>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="onTheMove(item, index)">上移动</el-dropdown-item>
                                    <el-dropdown-item @click.native="underTheMove(item, index)">下移动</el-dropdown-item>
                                    <el-dropdown-item @click.native="delBlockItemFunc(index)">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="editMenu">
                <div class="titleBox" v-if="imgList.length">
                    <div style="width: 8%;">标题名称：</div>
                    <el-input v-model="editListMode.title" style="width: 50%;"></el-input>
                </div>
                <div style="height: 80%;width: 100%;display: flex;padding-top: 40px;" v-if="imgList.length">
                    <div style="width: 8%;">图片上传:</div>
                    <el-upload class="upload-demo" style="height: 100%;width: 90%;" drag action="" :show-file-list="false"
                        :http-request="onUpload">
                        <!-- <i class="el-icon-upload"></i> -->
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </div>

                <div v-if="imgList.length" style="margin-top: 80px;display: flex; justify-content: end;">
                    <el-button>取消</el-button>
                    <el-button @click="savefunc">保存</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { mapGetters } from "vuex";
import { uploadImage,uploadMedia} from '@/api/common/upload'
import pdf from 'vue-pdf'
import VideoPlayer from "../vueVideoPlayer/videoPlayer"
import "@wangeditor/editor/dist/css/style.css"
export default {
    components: { Editor, Toolbar ,VideoPlayer,pdf},
    props: {
        cardIndex: {
            type: Object,
            default: () => ""
        },
        imagesEidtDialogType: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {

            editListMode: {
                id: 0,
                title: "",
                src: "",
            },
            cardList: "",
            cardChild: "",
            textItemList: [],
            editor: null,
            toolbarConfig: {},
            editorConfig: { placeholder: '请输入内容...' },
            mode: 'default', // or 'simple'
            nameType: "",
            imgList: []
        }
    },
    mounted() {
        this.init()

    },

    computed: {
        dragItemName(){
            return this.$parent.dragItemName?.name
        },
        /**
         * @Author: YangXin
         * @Description: 从store获取元素
         * @Date: 2024-08-07 18:23:53
         * @return {*}
         */
        imgTextList() {
            return this.$store.getters.imgTextList;
        },
        currentDepartment() {
            return this.$store.getters["schema/schema"].value
        },
        ...mapGetters({
            getDataMapByKey: 'schema/dataMapByKey',    // 将 store.getters.count 映射到 this.count
        })

    },




    methods: {
        /**
         * @Author: YangXin
         * @Description: 初始化获取修改的数组
         * @Date: 2024-08-07 18:18:03
         * @return {*}
         */
        init: function () {

            // this.dragItemName = this.$parent.dragItemName?.name
            if (this.cardIndex) {
                this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
                this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
            } else {
                this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
                this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
            }
            console.log(this.cardChild.children)
            if (this.cardChild.children) {
                if(this.cardChild.children[0].type == "img"){
                    this.$parent.dragItemName = {name:"图片"}
                    this.imgList = this.cardChild.children[0].imgList
                }else if(this.cardChild.children[0].type == "pdf-viewer"){
                    this.$parent.dragItemName = {name:"pdf"}
                    this.imgList = this.cardChild.children[0].srcList
                }else if(this.cardChild.children[0].type == "videoItem"){
                    this.$parent.dragItemName = {name:"视频"}
                    this.imgList = this.cardChild.children[0].vodeoList
                }
                this.editListMode = this.imgList[0]
            } else {
                this.imgList = []
            }

        },

        /**
         * @Author: YangXin
         * @Description: 添加图片
         * @Date: 2024-08-07 18:21:32
         * @return {*}
         */
        addItemFunc: function () {
            if (this.dragItemName == "图片") {
                this.imgList.unshift({
                    type: "img",
                    id: this.imgList.length,
                    title: "",
                    url: null,
                    saveType: false,
                })
            }
            if (this.dragItemName == "pdf") {
                this.imgList.unshift({
                    title: "",
                    id: this.imgList.length,
                    src: "",
                    saveType: false,
                })
            }

            if (this.dragItemName == "视频") {
                this.imgList.unshift({
                    id: this.imgList.length,
                    title: "",
                    url: '',
                    saveType: false,

                })
            }
            if (this.imgList.length == 1) {
                this.editListMode = this.imgList[0]
            }
        },
        /**
          * @Author: YangXin
          * @Description: 上移动
          * @Date: 2024-08-15 14:52:12
          * @param {*} item
          * @param {*} index
          * @return {*}
          */
        onTheMove: function (item, index) {
            this.imgList.splice(index - 1, 0, item)
            this.imgList.splice(index + 1, 1)
        },

        /**
         * @Author: YangXin
         * @Description: 下移动
         * @Date: 2024-08-15 14:52:33
         * @param {*} item
         * @param {*} index
         * @return {*}
         */
        underTheMove: function (item, index) {
            this.imgList.splice(index + 2, 0, item)
            this.imgList.splice(index, 1)
        },

        /**
         * @Author: YangXin
         * @Description: 删除元素
         * @Date: 2024-08-15 14:58:00
         * @param {*} index
         * @return {*}
         */
        delBlockItemFunc: function (index) {
            this.imgList.splice(index, 1)
        },
        /**
         * @Author: YangXin
         * @Description: 上传图片
         * @Date: 2024-08-13 16:32:47
         * @param {*} data
         * @return {*}
         */
        onUpload: function (data) {
            if (this.dragItemName == "图片") {
                uploadImage(data.file, progress => {
                    if (progress.total > 0) {
                        progress.percent = (progress.loaded / progress.total) * 100
                    }
                    data.onProgress(progress)
                }).then(res => {
                    this.editListMode.url = res.image_url,
                    this.$message({message: '上传成功',type: 'success'});
                })
            }
            if(this.dragItemName == "视频"){
                    uploadMedia(data.file)
                        .then(res => {
                            this.editListMode.url = res.url
                            // document.getElementById("myVideo" + this.editListMode.id).load();
                            this.$message({message: '上传成功',type: 'success'});
                        })
            }

            if(this.dragItemName == "pdf"){
                    uploadMedia(data.file).then(res => {
                        console.log(res)
                    this.editListMode.title = res.original_name
                    this.editListMode.src = res.url
                    this.editListMode.media_key = res.media_key
                        // document.getElementById("myVideo" + this.editListMode.id).load();
                        this.$message({
                                    message: '上传成功',
                                    type: 'success'
                                });
                        })
            }

        },
        /**
         * @Author: YangXin
         * @Description: 上传图片回显
         * @Date: 2024-08-07 18:18:07
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess(res, file) {
            this.editListMode.images = URL.createObjectURL(file.raw);
        },
        /**
         * @Author: YangXin
         * @Description:
         * @Date: 2024-08-07 18:18:12
         * @param {*} editor
         * @return {*}
         */
        onCreated(editor) {
            this.editor = Object.seal(editor)
        },
        /**
         * @Author: YangXin
         * @Description: 点击获取修改的item
         * @Date: 2024-08-08 09:07:28
         * @param {*} item
         * @return {*}
         */
        getItemFunc: function (item, index) {
            console.log(item)
            this.editListMode = item


        },

        /**
         * @Author: YangXin
         * @Description:
         * @Date: 2024-08-08 10:17:20
         * @return {*}
         */
        savefunc: function () {
            let item = null
            this.imgList.map((res,index) => {
                if (res.id == this.editListMode.id && res.saveType == false) {
                    // this.editListMode = true
                    this.imgList[index].saveType = true
                    item = res
                }
            })
            if (item) {
                if (this.cardChild.children && this.cardIndex?.type !== true ) {
                    if(this.dragItemName == "图片"){
                        this.cardChild.children[0].imgList.push({id:item.id,title:item.title,url:item.url})
                        // this.cardChild.children.push(item)
                    }
                    if(this.dragItemName == "视频"){
                        this.cardChild.children[0].vodeoList.push( {id:item.id,title:item.title,url:item.url})
                    }
                    // if(this.dragItemName == "视频"){
                    //     this.cardChild.children.push( {"type":"videoItem",vodeoList:[{url:item.url}]})
                    // }
                    if(this.dragItemName == "pdf"){

                        this.cardChild.children[0].srcList.push({id:item.id,title:item.title,src:item.src,media_key:item.media_key})
                        // this.cardChild.children.push( {"type":"videoItem",vodeoList:[{url:item.url}]})
                    }
                } else if(this.cardChild.children == "") {
                    this.cardChild.children = []
                    if(this.dragItemName == "图片"){
                        this.cardChild.children.push({"type":"img",imgList:[{id:item.id,title:item.title,url:item.url}]})
                    }
                    if(this.dragItemName == "视频"){
                        this.cardChild.children.push( {"type":"videoItem",vodeoList:[{id:item.id,title:item.title,url:item.url}]})
                    }
                    if(this.dragItemName == "pdf"){
                        this.cardChild.children = [{"type":"pdf-viewer",title:item.title,srcList:[{ id:item.id,title:item.title,src:item.src}]}]
                    }
                }
                       this.$message({
                          message: '保存成功',
                          type: 'success'
                        });
                // alert("成功")
            }

            // let item = this.getDataMapByKey(this.nameType)[this.editListMode.id]

            // item.title = this.editListMode.title
            // item.src = this.editListMode.images
            // item.isSave = true
        },

        /**
         * @Author: YangXin
         * @Description:
         * @Date: 2024-08-08 10:41:22
         * @return {*}
         */
        closeFunc: function () {
            // console.log(this.cardChild.children)
            // this.imgTextList.data = this.textItemList
            // this.cardChild.children = [JSON.parse(JSON.stringify(this.imgTextList))]
            this.$parent.imagesEidtDialogType = false
        }

    },



}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
    width: 80% !important;
    border-radius: 10px;
    background: #F0F0F0;


}

/deep/ .el-upload {
    height: 100%;
    width: 100%;

    .el-upload-dragger {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}

.titleBox {
    width: 100%;
    display: flex;

}

// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #9c2b2b !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF !important;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.editMain {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .menuList {
        width: 30%;
        padding-top: 10px;
        padding: 10px;
        background: #ffffff;
        // border: 1px solid black;
        height: 725px;

        .navBox {
            cursor: pointer;
            height: 60px;
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
            border-radius: 8px;
            color: #0074AB;
            border: 1px solid #0074AB;
        }

        .navBox:hover,
        .itemBox:hover {
            background: #F0F0F0;
        }

        .bttomBox {
            padding-top: 10px;
            height: calc(100% - 60px);
            overflow-y: scroll !important;
            /* 或者 overflow-x: scroll; 根据需要选择 */
        }

        .itemBox {
            width: 100%;
            cursor: pointer;
            position: relative;
            height: 150px;
            padding: 5px 8px;
            border-radius: 4px;
            display: flex;
            border: 1px solid #0074AB;

        }

        .itemBox2 {
            background: #F0F0F0;

            // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
        }
    }

    .editMenu {
        padding-top: 10px;
        margin-left: 10px;
        background: #ffffff;
        height: 725px;
        width: 69%;
        padding: 24px 20px;




        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}

.topMenu {
    top: 0;
    right: 0;
    position: absolute;
}
</style>
